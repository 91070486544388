import { executeActionOnEnter, getExchangeRate, getInstFlag, setButtonState } from "./portfolioHelper";
import { formatInstrumentDecimal } from "../../../helpers/marketDataFormatter";
import scroll from "../../../helpers/scroll";
import showToaster from "../../toaster";
export function initTradeModal(totalAmountField, quantityField) {
  totalAmountField.innerHTML = formatInstrumentDecimal(0);
  quantityField.value = null;
  quantityField.focus();
}
export function setupTradeForm(element, currency, name, stockLastPrice, fundLastPrice, traderCurrencies, isModifyModal) {
  var _ref;
  var nameEl = element.getElementsByClassName("js_holding-name")[0];
  var holdingName = currency ? "".concat(name, " (").concat(currency, ")") : name;
  nameEl.innerHTML = holdingName;
  var flagEl = element.getElementsByClassName("js_holding-flag")[0];
  flagEl.innerHTML = getInstFlag(currency);
  var lastPriceEl = element.getElementsByClassName("js_trade-holding-form__last-price")[0];
  var lastPriceValue = (_ref = stockLastPrice !== null && stockLastPrice !== void 0 ? stockLastPrice : fundLastPrice) !== null && _ref !== void 0 ? _ref : 0;
  lastPriceEl.setAttribute("value", lastPriceValue);
  var exchangeRateField = element.getElementsByClassName("js_trade-holding-form__exchange-rate")[0];
  exchangeRateField.classList.add("hidden");
  var averagePriceCurrency = element.getElementsByClassName("js_trade-holding-form__currency")[0];
  averagePriceCurrency.innerHTML = "Pris ".concat(currency);
  if (currency !== "SEK") {
    var exchangeRate = exchangeRateField.getElementsByClassName("js_trade-holding-form__exchange-rate-value")[0];
    exchangeRate.setAttribute("value", isModifyModal ? traderCurrencies : getExchangeRate(traderCurrencies, currency));
    exchangeRateField.classList.remove("hidden");
  }
}
export function tradeModalValuesHandler(modal, action, isFund, initialTotalAmount) {
  var totalAmountField = modal.getElementsByClassName("js_portfolio-modal__calculated-value")[0];
  var quantityField = modal.getElementsByClassName("js_trade-holding-form__amount")[0];
  var instLastPrice = modal.getElementsByClassName("js_trade-holding-form__last-price")[0];
  var submitBtn = modal.getElementsByClassName("js_trade-holding-form__submit-btn")[0];
  if (!totalAmountField || !quantityField || !instLastPrice || !submitBtn) return;
  quantityField.setAttribute("step", isFund ? "0.0001" : "1");
  var exchangeRate = modal.getElementsByClassName("js_trade-holding-form__exchange-rate-value")[0];
  var isModifyTradeModal = !!initialTotalAmount;
  var initialOwnedQuantity = isModifyTradeModal ? modal.getElementsByClassName("js_trade-holding__quantity")[0].innerHTML.replace(" st", "") : null;
  var submitBtnClass = "portfolio-modal__submit-btn";
  ["keyup", "change"].forEach(function (event) {
    [quantityField, instLastPrice, exchangeRate].forEach(function (el) {
      return el === null || el === void 0 ? void 0 : el.addEventListener(event, onInputChange);
    });
  });
  function onInputChange(event) {
    var inputQuantity = quantityField.value;
    var inputPrice = instLastPrice.value;
    var inputExchangeRate = (exchangeRate === null || exchangeRate === void 0 ? void 0 : exchangeRate.value) || 1;
    var totalValue = inputQuantity * inputPrice * inputExchangeRate;
    totalAmountField.innerHTML = formatInstrumentDecimal(totalValue);
    if (isModifyTradeModal) {
      setButtonState(submitBtn, quantityField, submitBtnClass, initialOwnedQuantity);
      setButtonState(submitBtn, {
        value: totalValue
      }, submitBtnClass, initialTotalAmount);
    } else {
      setButtonState(submitBtn, quantityField);
    }
    executeActionOnEnter(submitBtn, event.key, action);
  }
}
export function errorNotice(modal) {
  modal === null || modal === void 0 || modal.classList.add("hidden");
  scroll.enableScroll();
  showToaster("Något gick fel, försök igen om en liten stund.");
}