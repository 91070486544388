import { addRowClickEvents, getAllTableParts, getColumnHeaders, getDeformaters, getTableBody, getTableRows, markChanges, setupBackendSorting, setupColumnHeaders } from "./instrumentTableGeneral";
export default function InstrumentTableDesktop(element) {
  var instrumentTable = element;
  var sortable = instrumentTable.dataset.sortable === "true";
  var backendSorted = instrumentTable.dataset.backendSorted === "true";
  var tableRowMap = {};
  var tableData = [];
  var tables = getAllTableParts(instrumentTable);
  var columnHeaders = getColumnHeaders(tables);
  var tableRows = getTableRows(tables);
  markChanges(tables);
  window.addEventListener("load", function () {
    addRowClickEvents(tableRows);
    if (backendSorted) {
      setupBackendSorting(columnHeaders);
    } else if (sortable) {
      setupColumnHeaders(columnHeaders, tableData, updateTable);
      prepareSorting();
    }
  });
  function prepareSorting() {
    var deformation = getDeformaters(columnHeaders);
    tableRows.forEach(function (tr) {
      var tds = [].slice.call(tr.getElementsByTagName("TD"));
      var dataRow = [tr.id];
      tds.forEach(function (td, index) {
        var _td$firstChild;
        var value = ((_td$firstChild = td.firstChild) === null || _td$firstChild === void 0 ? void 0 : _td$firstChild.innerHTML) || td.innerHTML;
        dataRow.push(deformation[index] ? deformation[index](value) : value);
      });
      tableData.push(dataRow);
      tableRowMap[tr.id] = tr;
    });
  }
  function updateTable() {
    var adCount = instrumentTable.dataset.adCount;
    var maxDataRowsBetween = instrumentTable.dataset.maxDataRowsBetween;
    var currentTableIndex = 0;
    var tableBody = getTableBody(tables, currentTableIndex, adCount);
    tableData.forEach(function (rowData, i) {
      if (Math.floor(i / maxDataRowsBetween) > currentTableIndex) {
        currentTableIndex++;
        tableBody = getTableBody(tables, currentTableIndex, adCount);
      }
      tableBody.appendChild(tableRowMap[rowData[0]]);
    });
  }
}