function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { toggleDropdownInputs, toggleFollowButtons, toggleInputFields } from "./toggleInputs";
import forEach from "../../helpers/forEach";
import maxNumOfChipsReached from "./maxNumOfChipsReached";
import { toggleBtn } from "./handleFollowButton";

// chips === de små brickorna man ser i modalen för ens inställningar
export default function handleChips() {
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  var tagChips = context.getElementsByClassName("js_di-plus-pro-settings__chip");
  if (tagChips !== null && tagChips !== void 0 && tagChips.length) {
    var _iterator = _createForOfIteratorHelper(tagChips),
      _step;
    try {
      var _loop = function _loop() {
        var chip = _step.value;
        var closeIcon = chip.getElementsByClassName("icon--feather-close")[0];
        closeIcon.addEventListener("click", function () {
          return removeChip(chip);
        });
      };
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        _loop();
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
}
function removeChip(chip) {
  var id = chip.getAttribute("id");
  var type = chip.getAttribute("type");
  var dropdowns;
  if (type === "tag" || type === "stocks") {
    updatePreview(id, type);
  } else {
    dropdowns = document.getElementsByClassName("js_filter-dropdown");
    updateDropdown(dropdowns, id, type);
  }
  chip.remove();
  var errorMessage = document.getElementsByClassName("js_di-plus-pro-settings__max-chips-error")[0];
  if (errorMessage) {
    var watchesType = errorMessage.getAttribute("data-type");
    if (!maxNumOfChipsReached()) {
      var settingsAreaClass = watchesType === "instruments" ? "di-plus-pro-settings__section-market" : "js_di-plus-pro-settings";
      var settingsArea = document.getElementsByClassName(settingsAreaClass)[0];
      dropdowns = watchesType === "instruments" ? settingsArea.getElementsByClassName("js_filter-dropdown") : dropdowns;
      forEach(dropdowns, function (dropdown) {
        var inputElms = dropdown.getElementsByClassName("js_filter-dropdown__hidden-input");
        toggleDropdownInputs(inputElms, "enable");
      });
      var inputFields = settingsArea.getElementsByClassName("js_input-field__input");
      toggleInputFields(inputFields, "enable");
      var followButtons = settingsArea.getElementsByClassName("js_follow-button");
      toggleFollowButtons(followButtons, "enable");
      errorMessage.remove();
    }
  }
}
function isElement(element) {
  var _ref;
  return (_ref = element instanceof window.Element) !== null && _ref !== void 0 ? _ref : element instanceof window.HTMLElement;
}
function updateTitle(dropdown) {
  var allBoxes = Object.values(dropdown.getElementsByClassName("filter-dropdown__list-label"));
  var activeLists = allBoxes.filter(function (item) {
    var _item$querySelector;
    return isElement(item) && ((_item$querySelector = item.querySelector("input[type=checkbox][data-id]")) === null || _item$querySelector === void 0 ? void 0 : _item$querySelector.checked);
  });
  var altTitle = dropdown.dataset.altTitle;
  var title = dropdown.dataset.title;
  var button = dropdown.getElementsByClassName("js_filter-dropdown__button")[0];
  if (activeLists.length > 0) {
    button.innerHTML = "".concat(altTitle, " (").concat(activeLists.length, ")");
  } else {
    button.innerHTML = title;
  }
}
function updateDropdown(dropdowns, id, type) {
  forEach(dropdowns, function (dropdown) {
    if (dropdown.getAttribute("data-type") === type) {
      var inputElms = dropdown.getElementsByClassName("js_filter-dropdown__hidden-input");
      forEach(inputElms, function (el) {
        if (el.getAttribute("data-id") === id) {
          el.removeAttribute("checked");
          el.checked = false;
          updateTitle(dropdown);
        }
      });
    }
  });
  handleChips();
}
function updatePreview(id, type) {
  var preview = document.getElementsByClassName("js_di-plus-pro-settings__search-result")[0] || document.getElementsByClassName("js_market-search-preview")[0];
  if (!preview) return;
  var buttons = document.getElementsByClassName("js_follow-button");
  forEach(buttons, function (button) {
    var idAttr = type === "tag" ? "data-tag-id" : "data-insref";
    if (button.getAttribute("data-type") === type && button.getAttribute(idAttr) === id) {
      var btnTxt = button.getElementsByClassName("follow-button__text")[0];
      toggleBtn(true, btnTxt, button, true, false, "btn--red", "btn--blue-dark", type);
    }
  });
}