import forEach from "../../../helpers/forEach";
import initModal from "../../modal";
export default function portfolioModal(modalId, triggerClass, inputElClass, newRows) {
  var modalEl = document.getElementById(modalId);
  if (!modalEl) return;
  var modal = initModal(modalEl);
  var triggerElements;
  if (newRows) {
    triggerElements = newRows.map(function (r) {
      return Array.from(r.getElementsByClassName(triggerClass));
    }).flat();
  } else {
    triggerElements = document.getElementsByClassName(triggerClass);
  }
  forEach(triggerElements, function (element) {
    return element.addEventListener("click", clickHandler);
  });
  var inputEl = inputElClass ? modalEl.getElementsByClassName(inputElClass)[0] : null;
  function clickHandler(e) {
    modal.open();
    if (inputEl) inputEl.focus();
    e.stopPropagation();
  }
}