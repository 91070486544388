export default function portfolioDropdown() {
  var portfolioDetails = document.getElementsByClassName("portfolio-details")[0];
  var dropdownEl = portfolioDetails === null || portfolioDetails === void 0 ? void 0 : portfolioDetails.getElementsByClassName("js_portfolio-dropdown")[0];
  var dropdownBtn = portfolioDetails === null || portfolioDetails === void 0 ? void 0 : portfolioDetails.getElementsByClassName("js_portfolio-dropdown__button")[0];
  if (!dropdownEl || !dropdownBtn) return;
  var overlay = document.getElementsByClassName("js_site-overlay")[0];
  if (!overlay) return;
  var icon = document.getElementsByClassName("portfolio-details__dropdown-icon")[0];
  if (!icon) return;
  [dropdownBtn, overlay].forEach(function (el) {
    return el.addEventListener("click", function () {
      overlay.classList.toggle("hidden");
      dropdownEl.classList.toggle("hidden");
      icon.classList.toggle("portfolio-details__dropdown-icon--active");
    });
  });
}