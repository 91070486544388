import { addRowClickEvents, getAllTableParts, getColumnHeaders, getDeformaters, getTableBody, getTableRows, markChanges, setupBackendSorting, setupColumnHeaders } from "./instrumentTableGeneral";
export default function instrumentTableMobile(element) {
  var instrumentTable = element;
  var sortable = instrumentTable.dataset.sortable === "true";
  var backendSorted = instrumentTable.dataset.backendSorted === "true";
  var fixedTableRowsMap = {};
  var scrollableTableRowsMap = {};
  var tableData = [];
  var tableWrappers = getAllTableParts(instrumentTable);
  var columnHeaders = getColumnHeaders(tableWrappers);
  var fixedTables = tableWrappers.map(function (el) {
    return el.getElementsByClassName("js_instrument-table__fixed-part")[0];
  });
  var scrollableTables = tableWrappers.map(function (el) {
    return el.getElementsByClassName("js_instrument-table-scroll__scrollable-part")[0];
  });
  var fixedRows = getTableRows(fixedTables);
  var scrollableRows = getTableRows(scrollableTables);
  markChanges(scrollableTables);
  window.addEventListener("load", function () {
    addRowClickEvents(fixedRows, scrollableRows);
    if (backendSorted) {
      setupBackendSorting(columnHeaders);
    } else if (sortable) {
      setupColumnHeaders(columnHeaders, tableData, updateTable);
      prepareSorting();
    }
  });
  function prepareSorting() {
    var deformation = getDeformaters(columnHeaders);
    fixedRows.forEach(function (fixedTr, i) {
      var scrollableTr = scrollableRows[i];
      var allCells = [].concat([].slice.call(fixedTr.getElementsByTagName("TD")), [].slice.call(scrollableTr.getElementsByTagName("TD")));
      var id = fixedTr.id;
      var dataRow = [id];
      allCells.forEach(function (td, index) {
        var _td$firstChild;
        var value = ((_td$firstChild = td.firstChild) === null || _td$firstChild === void 0 ? void 0 : _td$firstChild.innerHTML) || td.innerHTML;
        dataRow.push(deformation[index] ? deformation[index](value) : value);
      });
      tableData.push(dataRow);
      fixedTableRowsMap[id] = fixedTr;
      scrollableTableRowsMap[id] = scrollableTr;
    });
  }
  function updateTable() {
    var adCount = instrumentTable.dataset.adCount;
    var maxDataRowsBetween = instrumentTable.dataset.maxDataRowsBetween;
    var currentTableIndex = 0;
    var fixedTableBody = getTableBody(fixedTables, currentTableIndex, adCount);
    var scrollableTableBody = getTableBody(scrollableTables, currentTableIndex, adCount);
    tableData.forEach(function (rowData, i) {
      if (Math.floor(i / maxDataRowsBetween) > currentTableIndex) {
        currentTableIndex++;
        fixedTableBody = getTableBody(fixedTables, currentTableIndex, adCount);
        scrollableTableBody = getTableBody(scrollableTables, currentTableIndex, adCount);
      }
      fixedTableBody.appendChild(fixedTableRowsMap[rowData[0]]);
      scrollableTableBody.appendChild(scrollableTableRowsMap[rowData[0]]);
    });
  }
}