import handleChips from "./handleChips";
import maxNumOfChipsReached from "./maxNumOfChipsReached";
export default function addToSettingsArea(type, id, name) {
  var _document$getElements;
  var isEditorial = type === "tag" || type === "industry" ? true : false;
  var sectionClass = isEditorial ? "js_di-plus-pro-settings__section-editorial" : "js_di-plus-pro-settings__section-market";
  var area = (_document$getElements = document.getElementsByClassName(sectionClass)[0]) === null || _document$getElements === void 0 ? void 0 : _document$getElements.getElementsByClassName("js_di-plus-pro-settings__chips")[0];
  if (!area) return;
  var chip = "\n    <span\n    class='js_di-plus-pro-settings__chip ".concat(!isEditorial && "js_di-plus-pro-settings__chip--instrument", " di-plus-pro-settings__chip'\n    id=").concat(id, "\n    type=").concat(type, ">\n    ").concat(name, "\n      <svg class=\"icon icon--feather-close icon-feather\"><use href=\"#feather-close\"></use></svg>\n    </span>\n    ");
  area.insertAdjacentHTML("beforeend", chip);
  var addedSettingsArea = document.getElementsByClassName("js_di-plus-pro-settings__section-added")[0];
  if (addedSettingsArea) handleChips(addedSettingsArea);
  maxNumOfChipsReached(area);
}