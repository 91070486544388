function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import addToSettingsArea from "./addToSettingsArea";
import removeFromSettingsArea from "./removeFromSettingsArea";
export { handleFollowButton, getFollowButton, toggleBtn, handleBtnClick };
function handleFollowButton() {
  var followButtons = document.getElementsByClassName("js_follow-button");
  if (followButtons !== null && followButtons !== void 0 && followButtons.length) {
    var _iterator = _createForOfIteratorHelper(followButtons),
      _step;
    try {
      var _loop = function _loop() {
        var button = _step.value;
        button.addEventListener("click", function () {
          return handleBtnClick(button);
        });
      };
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        _loop();
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
}
function handleBtnClick(followButton) {
  if (!followButton) return;
  var _followButton$dataset = followButton.dataset,
    type = _followButton$dataset.type,
    styleDefault = _followButton$dataset.styleDefault,
    styleFollow = _followButton$dataset.styleFollow;
  var isFollowed, id, name;
  if (type === "tag") {
    var _followButton$dataset2 = followButton.dataset,
      tagIsFollowed = _followButton$dataset2.tagIsFollowed,
      tagId = _followButton$dataset2.tagId,
      tagName = _followButton$dataset2.tagName;
    isFollowed = tagIsFollowed === "true";
    id = tagId;
    name = tagName;
  } else if (type === "stocks") {
    var _followButton$dataset3 = followButton.dataset,
      instrumentIsFollowed = _followButton$dataset3.instrumentIsFollowed,
      insref = _followButton$dataset3.insref,
      instrumentName = _followButton$dataset3.instrumentName,
      instrumentTradecurrency = _followButton$dataset3.instrumentTradecurrency;
    isFollowed = instrumentIsFollowed === "true";
    id = insref;
    name = "".concat(instrumentName, " (").concat(instrumentTradecurrency, ")");
  }
  var buttonText = followButton.getElementsByClassName("follow-button__text")[0];
  var iconCheck = followButton.getElementsByClassName("icon--feather-check")[0];
  var iconPlus = followButton.getElementsByClassName("icon--feather-plus")[0];
  followButton.setAttribute("disabled", "disabled");
  toggleBtn(isFollowed, buttonText, followButton, iconCheck, iconPlus, styleDefault, styleFollow, type);
  updateArea(isFollowed, type, id, name);
  sendToDataLayer(isFollowed, type, name);
}
function updateArea(isFollowed, type, id, name) {
  if (isFollowed) {
    removeFromSettingsArea(type, id);
  } else {
    addToSettingsArea(type, id, name);
  }
}
function toggleClasses(elements, classArray) {
  classArray.forEach(function (className) {
    return elements.forEach(function (el) {
      return el.classList.toggle(className);
    });
  });
}
function toggleBtn(isFollowed, buttonText, followButton, iconCheck, iconPlus, classDefault, classFollow, type) {
  buttonText.textContent = isFollowed ? "Lägg till" : "Tillagd";
  toggleClasses([followButton], [classDefault, classFollow]);
  var attribute = type === "tag" ? "data-tag-is-followed" : "data-instrument-is-followed";
  followButton.setAttribute(attribute, isFollowed ? false : true);
  if (iconCheck && iconPlus) {
    toggleClasses([iconCheck, iconPlus], ["hidden"]);
  }
  followButton.removeAttribute("disabled");
}
function sendToDataLayer(tagIsFollowed, type, name) {
  var action = tagIsFollowed ? "Unsubscribe" : "Subscribe";
  window.dataLayer = window.dataLayer || [];
  var dataLayer = {
    event: "Di Plus Pro",
    eventInfo: {
      action: "Di Plus Pro ".concat(action)
    }
  };
  var key = type === "tag" ? "tagName" : "instrumentName";
  dataLayer.eventInfo[key] = name;
  window.dataLayer.push(dataLayer);
}
function getFollowButton(baseClass) {
  var _markedRow$0$getEleme, _markedRow$;
  var markedRow = document.getElementsByClassName("".concat(baseClass, "--marked"));
  return (_markedRow$0$getEleme = (_markedRow$ = markedRow[0]) === null || _markedRow$ === void 0 ? void 0 : _markedRow$.getElementsByTagName("button")[0]) !== null && _markedRow$0$getEleme !== void 0 ? _markedRow$0$getEleme : null;
}