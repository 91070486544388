import forEach from "../../helpers/forEach";
export function toggleDropdownInputs(dropdownInputs, action) {
  if (!(dropdownInputs !== null && dropdownInputs !== void 0 && dropdownInputs.length)) return;
  forEach(dropdownInputs, function (input) {
    if (!input.checked) {
      var listLabel = input.parentElement;
      if (action === "disable") {
        input.disabled = true;
        listLabel.classList.add("filter-dropdown__list-label--disabled");
      } else {
        input.disabled = false;
        listLabel.classList.remove("filter-dropdown__list-label--disabled");
      }
    }
  });
}
export function toggleInputFields(inputFields, action) {
  if (!(inputFields !== null && inputFields !== void 0 && inputFields.length)) return;
  forEach(inputFields, function (input) {
    var field = input.closest(".input-field");
    if (action === "disable") {
      input.disabled = true;
      field.classList.add("input-field--disabled");
    } else {
      input.disabled = false;
      field.classList.remove("input-field--disabled");
    }
  });
}
export function toggleFollowButtons(buttons, action) {
  if (!buttons.length) return;
  forEach(buttons, function (button) {
    if (action === "disable") {
      button.disabled = true;
      button.classList.add("follow-button--disabled");
    } else {
      button.disabled = false;
      button.classList.remove("follow-button--disabled");
    }
  });
}