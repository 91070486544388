import { toggleDropdownInputs, toggleFollowButtons, toggleInputFields } from "./toggleInputs";

// Man kan nå ett maximum som användare och det betyder att man max får bevaka 10 instrument och/eller 100 bevakningar totalt
export default function maxNumOfChipsReached() {
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  var errorMessage = context.getElementsByClassName("js_di-plus-pro-settings__max-chips-error")[0];
  var div;
  if (errorMessage) {
    div = errorMessage;
  } else {
    div = document.createElement("div");
    div.className = "js_di-plus-pro-settings__max-chips-error di-plus-pro-settings__max-chips-error";
  }
  var numOfInstrumentChips = context.getElementsByClassName("js_di-plus-pro-settings__chip--instrument").length;
  if (numOfInstrumentChips >= 10) {
    var _document$getElements;
    if (!errorMessage) {
      div.innerHTML = "<svg class='icon icon-feather icon--feather-alert-triangle'><use href='#feather-alert-triangle'></use></svg>" + "Du har nått det maximala antalet instrument. Om du vill lägga till ett nytt måste du ta bort ett annat.";
      var marketSearchInput = document.getElementsByClassName("di-plus-pro-settings__market-search")[0];
      marketSearchInput.insertAdjacentElement("beforebegin", div);
    }
    var marketSection = (_document$getElements = document.getElementsByClassName("js_di-plus-pro-settings")[0]) === null || _document$getElements === void 0 ? void 0 : _document$getElements.getElementsByClassName("di-plus-pro-settings__section-market")[0];
    var dropdownInputs = marketSection === null || marketSection === void 0 ? void 0 : marketSection.getElementsByClassName("js_filter-dropdown__hidden-input");
    toggleDropdownInputs(dropdownInputs, "disable");
    var inputFields = marketSection === null || marketSection === void 0 ? void 0 : marketSection.getElementsByClassName("js_input-field__input");
    toggleInputFields(inputFields, "disable");
    var followButtons = marketSection === null || marketSection === void 0 ? void 0 : marketSection.getElementsByClassName("js_follow-button");
    toggleFollowButtons(followButtons, "disable");
    return true;
  }
  var totalNumOfChips = context.getElementsByClassName("js_di-plus-pro-settings__chip").length;
  if (totalNumOfChips >= 100) {
    if (!errorMessage) {
      div.innerHTML = "<svg class='icon icon-feather icon--feather-alert-triangle'><use href='#feather-alert-triangle'></use></svg>" + "Du har nått det maximala antalet ämnen och instrument. Om du vill lägga till nytt måste du ta bort något först.";
      var editorialSection = document.getElementsByClassName("js_di-plus-pro-settings__section-editorial")[0];
      editorialSection.insertAdjacentElement("beforebegin", div);
    }
    var _dropdownInputs = document.getElementsByClassName("js_filter-dropdown__hidden-input");
    toggleDropdownInputs(_dropdownInputs, "disable");
    var _inputFields = document.getElementsByClassName("js_input-field__input");
    toggleInputFields(_inputFields, "disable");
    var _followButtons = document.getElementsByClassName("js_follow-button");
    toggleFollowButtons(_followButtons, "disable");
    return true;
  }
  return false;
}