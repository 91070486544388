function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { toggleDropdownInputs, toggleFollowButtons, toggleInputFields } from "./toggleInputs";
import handleChips from "./handleChips";
import maxNumOfChipsReached from "./maxNumOfChipsReached";
export default function removeFromSettingsArea(type, id) {
  var _document$getElements;
  var isEditorial = type === "tag" || type === "industry" ? true : false;
  var sectionClass = isEditorial ? "js_di-plus-pro-settings__section-editorial" : "js_di-plus-pro-settings__section-market";
  var area = (_document$getElements = document.getElementsByClassName(sectionClass)[0]) === null || _document$getElements === void 0 ? void 0 : _document$getElements.getElementsByClassName("js_di-plus-pro-settings__chips")[0];
  if (!area) return;
  var tagChips = area.getElementsByClassName("js_di-plus-pro-settings__chip");
  var _iterator = _createForOfIteratorHelper(tagChips),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var chip = _step.value;
      var chipId = chip.getAttribute("id");
      if (chipId === id) {
        chip.remove();
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  var addedSettingsArea = document.getElementsByClassName("js_di-plus-pro-settings__section-added")[0];
  if (addedSettingsArea) handleChips(addedSettingsArea);
  var errorMessage = document.getElementsByClassName("js_di-plus-pro-settings__max-chips-error")[0];
  if (errorMessage && !maxNumOfChipsReached(area)) {
    var watchesType = errorMessage.getAttribute("data-type");
    var settingsAreaClass = watchesType === "instruments" ? "di-plus-pro-settings__section-market" : "js_di-plus-pro-settings";
    var settingsArea = document.getElementsByClassName(settingsAreaClass)[0];
    var dropdownInputs = settingsArea.getElementsByClassName("js_filter-dropdown__hidden-input");
    toggleDropdownInputs(dropdownInputs, "enable");
    var inputFields = settingsArea.getElementsByClassName("js_input-field__input");
    toggleInputFields(inputFields, "enable");
    var followButtons = settingsArea.getElementsByClassName("js_follow-button");
    toggleFollowButtons(followButtons, "enable");
    errorMessage.remove();
  }
}