import portfolioBuyHolding from "./portfolioBuyHolding";
import portfolioDropdown from "./portfolioDropdown";
import portfolioModal from "./portfolioModal";
import portfolioProgressDropdown from "./portfolioProgressDropdown";
import portfolioTrade from "./portfolioTrade";
import portfolioTransaction from "./portfolioTransaction";
export default function portfolioHoldingsScripts() {
  portfolioDropdown();
  portfolioModal("portfolio-buy-modal", "trigger-portfolio-buy-modal");
  portfolioTrade("buy");
  portfolioModal("portfolio-sell-modal", "trigger-portfolio-sell-modal");
  portfolioTrade("sell");
  portfolioModal("portfolio-deposit-modal", "trigger-portfolio-deposit-modal");
  portfolioTransaction("deposit");
  portfolioModal("portfolio-withdrawal-modal", "trigger-portfolio-withdrawal-modal");
  portfolioTransaction("withdrawal");
  portfolioProgressDropdown("/innehav/");
  portfolioModal("portfolio-buy-holding-modal", "trigger-portfolio-buy-holdings-modal", "js_portfolio-buy__input");
  portfolioBuyHolding();
}