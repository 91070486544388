import { formatInstrumentDecimal } from "../../../helpers/marketDataFormatter";
import getElementValue from "../../../helpers/getElementValue";
import showToaster from "../../toaster";
export var FLAGS = {
  DKK: "dk",
  EUR: "eu",
  GBP: "gb",
  NOK: "no",
  SEK: "se",
  USD: "us"
};
var CURRENCY = {
  DKK: 21891,
  EUR: 21909,
  GBP: 21910,
  NOK: 22011,
  USD: 22143
};
var SIGN = {
  buy: "negative",
  deposit: "positive",
  sell: "positive",
  withdrawal: "negative"
};
export function calculateNewBalance(element, type, input) {
  var lastPrice = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
  var initialValue = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
  var calculatedValueField = element.getElementsByClassName("js_portfolio-modal__calculated-value")[0];
  var cash = element.getElementsByClassName("js_portfolio-modal__cash")[0].innerText;
  var inputValue = Math.abs(input) - Math.abs(initialValue);
  var cashValue = formattedStringToNumber(cash);
  var totalAmount = inputValue * parseFloat(lastPrice);
  var calculatedValue = SIGN[type] === "negative" ? cashValue - Math.abs(totalAmount) : cashValue + totalAmount;
  calculatedValueField.innerHTML = formatInstrumentDecimal(calculatedValue);
}
export function getExchangeRate(currencies, instCurrency) {
  var _exchangeRate$toFixed;
  var currency = currencies === null || currencies === void 0 ? void 0 : currencies.filter(function (c) {
    return c.insref === CURRENCY[instCurrency];
  })[0];
  if (!currency) return null;
  var lastprice = currency.lastprice,
    baseratio = currency.baseratio;
  var exchangeRate = lastprice / baseratio;
  return (_exchangeRate$toFixed = exchangeRate.toFixed(5)) !== null && _exchangeRate$toFixed !== void 0 ? _exchangeRate$toFixed : 0;
}
export function decodeTransactionType(element, type, ownedAmount) {
  var inputValue = parseFloat(getElementValue(element, "js_portfolio-modal__amount"));
  var ownedAmountUnsigned = Math.abs(ownedAmount);
  return type === "Uttag" || type === "Köp" ? inputValue > ownedAmountUnsigned ? "buy" : "sell" : inputValue < ownedAmountUnsigned ? "buy" : "sell";
}
export function getInstFlag(instCurrency) {
  var flagCode = FLAGS[instCurrency];
  return !flagCode ? "" : "<svg width='24' height='24' class='icon icon--flag-".concat(flagCode, " span-flag-icon'><use xlink:href=#flag-").concat(flagCode, "></use></svg>");
}
export function formattedStringToNumber(value) {
  return parseFloat(value.replace(/ /g, "").replace(",", "."));
}
export function indicatorClass(value) {
  var baseClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "portfolio-details__number-value";
  if (!value || parseFloat(value) === 0 || isNaN(value)) {
    return baseClass;
  }
  return value > 0 ? "".concat(baseClass, "--positive") : "".concat(baseClass, "--negative");
}
export function setButtonState(btn, input) {
  var _input$validity, _input$value;
  var btnClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "portfolio-modal__submit-btn";
  var initialValue = arguments.length > 3 ? arguments[3] : undefined;
  if (!input || input.value <= 0 || input.value === initialValue || ((_input$validity = input.validity) === null || _input$validity === void 0 ? void 0 : _input$validity.valid) === false) {
    btn.classList.add("".concat(btnClass, "--disabled"));
    btn.setAttribute("disabled", true);
  } else if (input.value > 0 || (_input$value = input.value) !== null && _input$value !== void 0 && _input$value.length && input.value !== initialValue) {
    btn.classList.remove("".concat(btnClass, "--disabled"));
    btn.removeAttribute("disabled");
  }
}
export function setButtonStateWithInitialValue(btn, input) {
  var _input$value$toString, _input$value2, _input$dataset$initia, _input$dataset;
  var btnClass = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "portfolio-modal__submit-btn";
  if (!btn || !input) return;
  var value = (_input$value$toString = (_input$value2 = input.value) === null || _input$value2 === void 0 ? void 0 : _input$value2.toString()) !== null && _input$value$toString !== void 0 ? _input$value$toString : null;
  var initialValue = (_input$dataset$initia = (_input$dataset = input.dataset) === null || _input$dataset === void 0 || (_input$dataset = _input$dataset.initialValue) === null || _input$dataset === void 0 ? void 0 : _input$dataset.toString()) !== null && _input$dataset$initia !== void 0 ? _input$dataset$initia : null;
  if (!value || value <= 0 || value === initialValue) {
    btn.classList.add("".concat(btnClass, "--disabled"));
    btn.setAttribute("disabled", true);
  } else if (value > 0 || value.length && value !== initialValue) {
    btn.classList.remove("".concat(btnClass, "--disabled"));
    btn.removeAttribute("disabled");
  }
}
export function handleResponse(response, errorString) {
  if (response.ok) {
    reloadPage();
  } else {
    showToaster("Något gick fel. ", errorString);
  }
}
export function reloadPage() {
  window.setTimeout(function () {
    window.location.reload();
  }, 50);
}
export function executeActionOnEnter(button, key, action) {
  if (!button.disabled) {
    if (key === "Enter") action();
  }
}