import forEach from "../../helpers/forEach";
export default function getUserItemsList(addedChips) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var userItems = [];
  forEach(addedChips, function (chip) {
    if (type && chip.getAttribute("type") !== type) return;
    userItems.push({
      itemId: chip.getAttribute("id"),
      itemType: chip.getAttribute("type") === "industry" ? "topic" : chip.getAttribute("type")
    });
  });
  return userItems;
}