export function removeOrcaSyntax(input) {
  var _input$replace$trim;
  if (!input) return null;
  return (_input$replace$trim = input.replace(/[\][]/g, "").trim()) !== null && _input$replace$trim !== void 0 ? _input$replace$trim : input;
}
export function hasOrcaSyntax(input) {
  if (!input) return null;
  var forbiddenChars = ["[", "]"];
  return forbiddenChars.some(function (_char) {
    return input.includes(_char);
  });
}